var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":140,"nudge-bottom":10,"offset-y":"","z-index":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-2 pr-1 mt-2",staticStyle:{"height":"29px !important","overflow":"visible !important"},attrs:{"color":_vm.selected_estatus.length > 0 ? 'primary' :'blue-grey lighten-5',"dark":_vm.selected_estatus.length > 0,"label":"","link":""}},'v-chip',attrs,false),on),[_c('span',{class:{'font-weight-bold': _vm.selected_estatus.length > 0 }},[_vm._v("Estatus")]),(_vm.selected_estatus.length > 0)?_c('span',[_vm._v(" : "+_vm._s(_vm.selected_estatus === '1' ? 'Inactivo' : _vm.selected_estatus === '0' ? 'Activo' : '')+" ")]):_vm._e(),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"22"},domProps:{"textContent":_vm._s(false ? 'mdi-menu-up' : 'mdi-menu-down' )}})],1)]}}])},[_c('v-card',[_c('v-card-text',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"my-0",attrs:{"hide-details":""},model:{value:(_vm.selected_estatus),callback:function ($$v) {_vm.selected_estatus=$$v},expression:"selected_estatus"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-radio',{attrs:{"color":"icono","value":"0"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cliente Activo")])],1)]}}])}),_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-radio',{attrs:{"color":"icono","value":"1"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cliente Inactivo")])],1)]}}])})],1)],1),_c('v-divider'),_c('v-btn',{staticClass:"ma-1 ",attrs:{"color":"blue-grey","plain":""},on:{"click":function($event){_vm.selected_estatus = ''}}},[_c('span',[_vm._v("Borrar selección")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }